import Swiper from 'swiper';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import pjax from "jquery-pjax";
$(function () {
	toppage();
	settingAnimation();
	pageSetting();
	//
	if (!$(".toppage__mainimage").length) {
		$(".loading").addClass('none');
	} else {
		$(window).on("load", function () {
			$("html,body").animate({ scrollTop: 0 }, "1")
			$(".loading").delay(3000).queue(function () {
				$(this).addClass("none").dequeue();

				setTimeout(() => {
					$(".toppage__mainimage .photo").addClass('anim');
					setTimeout(() => {
						$(".illustrator").each(function (index) {
							setTimeout(() => {
								$(this).addClass('anim');
							}, index * 100);
						});
						setTimeout(() => {
							$(".toppage__content").css({
								opacity: 1
							})
							$(".toppage__subsidy").css({
								opacity: 1
							})
						}, 500)
					}, 500);
				}, 1000);
			})
		});
	}
	function pageSetting() {
		$('.nav__global li').removeClass('is-active');
		const nowUrl = location.href;
		if (nowUrl.match(/tedasochima_news/)) {
			$('.nav__global .nav__company').addClass('is-active');
		} else if (nowUrl.match(/news/)) {
			$('.nav__global .nav__news').addClass('is-active');
		} else if (nowUrl.match(/about/)) {
			$('.nav__global .nav__about').addClass('is-active');
		} else if (nowUrl.match(/properties/)) {
			$('.nav__global .nav__properties').addClass('is-active');
		} else if (nowUrl.match(/recruitment/)) {
			$('.nav__global .nav__recruitment').addClass('is-active');
		} else if (nowUrl.match(/company/)) {
			$('.nav__global .nav__company').addClass('is-active');
		} else if (nowUrl.match(/contact/)) {
			$('.nav__global .nav__contact').addClass('is-active');
		} else if (nowUrl.match(/trial/)) {
			$('.nav__global .nav__trial').addClass('is-active');
		}
	}
	$(document).on("click", ".link", function (e) {
		e.preventDefault();
		var href = $(this).attr('href');
		$(".loading__logo").addClass('none');
		$(".loading").removeClass('none');
		$(".nav__bar").removeClass('is-active');
		$(".nav").removeClass('is-active');

		setTimeout(function () {
			$.pjax({
				url: href,
				container: ".contents",
				fragment: ".contents",
				scrollTo: 0,
				timeout: 5000
			})
		}, 800)
	})
	$(document).on("click", ".nav__bar", function (e) {
		$(this).toggleClass('is-active');
		$(".nav").toggleClass('is-active');
	});
	$(document).on('pjax:end', function () {
		// console.log('pjaxが終了したときに発生');
		$(".loading").addClass('none');
		$("html,body").animate({ scrollTop: 0 }, "1")
		pageSetting();
		settingAnimation();
		toppage();
		Ts.loadFont();
		// loadingAnimation()
	});
});

function toppage() {
	$(".toppage__content").css({
		opacity: 0
	})
	if ($(".loading").hasClass('none')) {
		setTimeout(() => {
			$(".toppage__mainimage .photo").addClass('anim');
			setTimeout(() => {
				$(".illustrator").each(function (index) {
					setTimeout(() => {
						$(this).addClass('anim');
					}, index * 100);
				});
				setTimeout(() => {
					$(".toppage__content").css({
						opacity: 1
					})
					$(".toppage__subsidy").css({
						opacity: 1
					})
				}, 500)
			}, 2500);
		})
	}
	$(".fadeup").each(function () {
		gsap.registerPlugin(ScrollTrigger);
		gsap.fromTo(
			$(this), {
			opacity: 0,
			y: 20
		}, {
			opacity: 1,
			y: 0,
			duration: 1,
			scrollTrigger: {
				// markers: "true",
				trigger: $(this),
				start: 'top 90%',
				toggleActions: "restart none none reverse"
			}
		});
	});
	$(".toppage__news__list .item").each(function () {
		gsap.registerPlugin(ScrollTrigger);
		if ($(window).width() > 768) {
			const $photo = $(this).find(".photo");
			const $body = $(this).find(".body");
			const animateIn = gsap.timeline({
				scrollTrigger: {
					trigger: $(this),
					start: 'top 90%',
					// markers: "true",
					toggleActions: "play none none reverse",
				}
			});
			gsap.fromTo(
				$(this), {
				opacity: 0,
			}, {
				opacity: 1,
				duration: 1,
				scrollTrigger: {
					trigger: $(this),
					start: 'top 90%',
					// markers: "true",
					toggleActions: "play none none reverse",
				}
			});

			gsap.fromTo(
				$photo, {
				opacity: 0,
				y: -50
			}, {
				opacity: 1,
				y: 0,
				duration: 1,
				scrollTrigger: {
					trigger: $(this),
					start: 'top 90%',
					// markers: "true",
					toggleActions: "play none none reverse",
				}
			});
			gsap.fromTo(
				$body, {
				opacity: 0,
				y: 50
			}, {
				opacity: 1,
				y: 0,
				duration: 1,
				scrollTrigger: {
					trigger: $(this),
					start: 'top 90%',
					// markers: "true",
					toggleActions: "play none none reverse",
				}
			});
		} else {
			gsap.fromTo(
				$(this), {
				opacity: 0,
				y: -50
			}, {
				opacity: 1,
				y: 0,
				duration: 1,
				scrollTrigger: {
					trigger: $(this),
					start: 'top 90%',
					// markers: "true",
					toggleActions: "play none none reverse",
				}
			});
		}
	});

	$(".toppage__properties__list .item").each(function (index) {
		gsap.registerPlugin(ScrollTrigger);
		let $num;
		if (index >= 3) {
			$num = index % 3;
		} else {
			$num = index;
		}
		gsap.fromTo(
			$(this), {
			opacity: 0,
			y: 50
		}, {
			opacity: 1,
			y: 0,
			duration: 1,
			delay: 0.25 * $num,
			scrollTrigger: {
				trigger: $(this),
				start: 'top 90%',
				// markers: "true",
				toggleActions: "play none none reverse",
			}
		});
	});
}
function settingAnimation() {
	//
	if ($(".propertiespage__list").length) {
		$(".propertiespage__list .item").each(function (index) {
			gsap.registerPlugin(ScrollTrigger);
			let $num;
			if (index >= 3) {
				$num = index % 3;
			} else {
				$num = index;
			}
			gsap.fromTo(
				$(this), {
				opacity: 0,
				y: 50
			}, {
				opacity: 1,
				y: 0,
				duration: 1,
				delay: 0.25 * $num,
				scrollTrigger: {
					trigger: $(this),
					start: 'center bottom',
					// markers: "true",
					toggleActions: "play none none reverse",
				}
			});
		});
	}
	if ($(".propertiespage__single").length) {

		var swiper = new Swiper(".thumbs", {
			spaceBetween: 0,
			slidesPerView: 5,
			slidesPerColumn: 2
		});
		var swiper2 = new Swiper(".mainimage", {
			spaceBetween: 10,
			effect: "fade",
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
			thumbs: {
				swiper: swiper,
			},
		});
	}

	if ($(".trialpage__single").length) {

		var swiper = new Swiper(".thumbs", {
			spaceBetween: 0,
			slidesPerView: 5,
			slidesPerColumn: 2
		});
		var swiper2 = new Swiper(".mainimage", {
			spaceBetween: 10,
			effect: "fade",
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
			thumbs: {
				swiper: swiper,
			},
		});
	}
	//
	if ($(".newspage__list").length) {
		$(".newspage__list .item").each(function (index) {
			// gsap.registerPlugin(ScrollTrigger);
			if ($(window).width() > 768) {
				const $photo = $(this).find(".photo");
				const $body = $(this).find(".body");
				gsap.fromTo(
					$(this), {
					opacity: 0,
				}, {
					opacity: 1,
					duration: 1,
					delay: 0.25 * index,
					scrollTrigger: {
						trigger: $(this),
						start: 'top 90%',
						// markers: "true",
						toggleActions: "play none none reverse",
					}
				});

				gsap.fromTo(
					$photo, {
					opacity: 0,
					y: -50
				}, {
					opacity: 1,
					y: 0,
					duration: 1,
					scrollTrigger: {
						trigger: $(this),
						start: 'top 90%',
						// markers: "true",
						toggleActions: "play none none reverse",
					}
				});
				gsap.fromTo(
					$body, {
					opacity: 0,
					y: 50
				}, {
					opacity: 1,
					y: 0,
					duration: 1,
					scrollTrigger: {
						trigger: $(this),
						start: 'top 90%',
						// markers: "true",
						toggleActions: "play none none reverse",
					}
				});
			} else {
				gsap.fromTo(
					$(this), {
					opacity: 0,
					y: -50
				}, {
					opacity: 1,
					y: 0,
					duration: 1,
					scrollTrigger: {
						trigger: $(this),
						start: 'top 90%',
						// markers: "true",
						toggleActions: "play none none reverse",
					}
				});
			}
		});
	}

	// about

	if ($(".aboutpage").length) {
		gsap.registerPlugin(ScrollTrigger);
		gsap.fromTo(
			".aboutpage__box .illustrator1", 2, {
			opacity: 0,
			x: -100
		}, {
			opacity: 1,
			x: 0,
			duration: 1,
			scrollTrigger: {
				trigger: ".aboutpage__box .illustrator1",
				start: 'center 100%',
				// markers: "true",
				toggleActions: "play none none reverse",
			}
		});

		gsap.fromTo(
			".aboutpage__box .illustrator2", 2, {
			opacity: 0,
			x: 100
		}, {
			opacity: 1,
			x: 0,
			duration: 1,
			delay: 0.5,
			scrollTrigger: {
				trigger: ".aboutpage__box .illustrator2",
				start: 'center 100%',
				// markers: "true",
				toggleActions: "play none none reverse",
			}
		});

		gsap.fromTo(
			".aboutpage__box .illustrator3", 2, {
			opacity: 0,
			x: 100
		}, {
			opacity: 1,
			x: 0,
			duration: 1,
			scrollTrigger: {
				trigger: ".aboutpage__box .illustrator3",
				start: 'center 100%',
				// markers: "true",
				toggleActions: "play none none reverse",
			}
		});
		gsap.fromTo(
			".aboutpage__box .illustrator4", 2, {
			opacity: 0,
			x: -100
		}, {
			opacity: 1,
			x: 0,
			duration: 1,
			scrollTrigger: {
				trigger: ".aboutpage__box .illustrator4",
				start: 'center 100%',
				// markers: "true",
				toggleActions: "play none none reverse",
			}
		});
		gsap.fromTo(
			".aboutpage__box .illustrator5", 2, {
			opacity: 0,
			x: -50,
			y: -10
		}, {
			opacity: 1,
			x: 0,
			y: 0,
			duration: 1,
			scrollTrigger: {
				trigger: ".aboutpage__box .illustrator5",
				start: 'center 100%',
				// markers: "true",
				toggleActions: "play none none reverse",
			}
		});
		gsap.fromTo(
			".aboutpage__box .illustrator6", 2, {
			opacity: 0,
			x: -50,
		}, {
			opacity: 1,
			x: 0,
			duration: 1,
			scrollTrigger: {
				trigger: ".aboutpage__box .illustrator6",
				start: 'top 90%',
				// markers: "true",
				toggleActions: "play none none reverse",
			}
		});
		gsap.fromTo(
			".aboutpage__box .illustrator7", 2, {
			opacity: 0,
			x: 50,
		}, {
			opacity: 1,
			x: 0,
			duration: 1,
			scrollTrigger: {
				trigger: ".aboutpage__box .illustrator7",
				start: 'top 90%',
				// markers: "true",
				toggleActions: "play none none reverse",
			}
		});
	}

	// company
	if ($(".companypage").length) {
		$(".companypage__about__vision h3").each(function (index) {
			$(this).removeClass("is-close");
			$(this).next().removeClass('is-close');
			$(this).next().css({
				height: $(this).next().outerHeight()
			})
			$(this).next().toggleClass('is-close');
			$(this).toggleClass('is-close');

			$(".companypage__project__list .item").each(function (index) {
				gsap.registerPlugin(ScrollTrigger);
				let $num;
				if (index >= 3) {
					$num = index % 3;
				} else {
					$num = index;
				}
				gsap.fromTo(
					$(this), {
					opacity: 0,
					y: 50
				}, {
					opacity: 1,
					y: 0,
					duration: 1,
					delay: 0.25 * $num,
					scrollTrigger: {
						trigger: '.companypage__project__list',
						start: 'top bottom',
						// markers: "true",
						toggleActions: "play none none reverse",
					}
				});
			});
		});
		$('.companypage__about__vision h3').on('click', function () {
			$(this).next().toggleClass('is-close');
			$(this).toggleClass('is-close');
		})
		$(".companypage__about__text .list li").each(function (index) {
			gsap.registerPlugin(ScrollTrigger);
			gsap.fromTo(
				$(this), {
				opacity: 0,
				x: 50
			}, {
				opacity: 1,
				x: 0,
				duration: 1,
				delay: 0.25 * index,
				scrollTrigger: {
					trigger: $(".companypage__about__text"),
					start: 'top 90%',
					// markers: "true",
					toggleActions: "play none none reverse",
				}
			});
		});

	}
}
